<template>
  <div class="route-schedulings-paginated" v-if="projects">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <DataTable
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
      ref="routeSchedulingsPaginatedTable"
      :value="projects"
      dataKey="number"
      :lazy="true"
      :loading="isLoading"
      :totalRecords="getProjectCount"
      :paginator="true"
      :rows.sync="tableState.pagination.rowsPerPage"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 15, 20, 25, 30, 40, 50, 100, 200]"
      :filters.sync="tableState.filters"
      stateStorage="local"
      :stateKey="tableState.filterName"
      filterDisplay="row"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      :rowHover="true"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <h4 class="page-title">{{ $t('route_scheduling_list') }}</h4>
          </div>
          <div class="table-header-right">
            <div v-if="isAdmin">
              <Dropdown
                :value="tableState.customFilters.client"
                :options="clients"
                @input="onClientFilter"
                optionLabel="label"
                :placeholder="$t('client')"
                style="min-width: 18em"
                :showClear="true"
              />
            </div>

            <div>
              <MultiSelect
                :value="tableState.customFilters.employers"
                :options="employers"
                @input="onEmployerFilter"
                optionLabel="label"
                :placeholder="$t('employer')"
                style="min-width: 18em"
                display="chip"
                :disabled="employerDropdownDisabled"
              />
            </div>

            <button class="btn btn-sm btn-inverse" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>
          </div>
        </div>
      </template>

      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column
        v-if="isAdmin"
        field="number"
        header="#"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data.number"
            :to="{
              name: 'ProjectEditPage',
              params: { projectNumber: slotProps.data.number },
            }"
            >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
          >
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="clientProjectNumber"
        header="#"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data.number && slotProps.data.clientProjectNumber"
            :to="{
              name: 'ProjectEditPage',
              params: { projectNumber: slotProps.data.number },
            }"
            >{{ slotProps.data.clientProjectNumber.toString().padStart(4, '0') }}</router-link
          >
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="customer.lastname"
        :header="$t('lastname')"
        filterField="customerLastname"
        sortable
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="customer.firstname"
        :header="$t('firstname')"
        filterField="customerFirstname"
        sortable
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="customer.street"
        :header="$t('street')"
        filterField="customerStreet"
        sortable
        sortField="customer.street"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="customer.zip"
        :header="$t('zip')"
        filterField="customerZip"
        sortable
        sortField="customer.zip"
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="customer.city" :header="$t('city')" filterField="customerCity" sortable>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="customer.phone" :header="$t('phone')" filterField="customerPhone" sortable>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="customer.mobile" :header="$t('mobile')" filterField="customerMobile" sortable>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="itemPlanningFinishedState"
        :header="$t('itemPlanningFinishedState_short')"
        sortable
        sortField="itemPlanningFinishedState"
        :headerStyle="{ 'max-width': '100px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <b-badge :variant="getYesNoStateColor(slotProps.data.itemPlanningFinishedState)">
            {{ $t(slotProps.data.itemPlanningFinishedState) }}
          </b-badge>
        </template>

        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="yesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <!-- <Column
        field="latestInstallationAppointment.installationTeam"
        :header="$t('installationTeam_short')"
        sortable
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span
            v-if="
              slotProps.data.latestInstallationAppointment &&
                slotProps.data.latestInstallationAppointment.installationTeam
            "
          >
            {{ $t(slotProps.data.latestInstallationAppointment.installationTeam) }}
          </span>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.installationTeams"
            :options="installationTeams"
            @input="onInstallationTeamFilter"
            optionLabel="label"
            :placeholder="$t('installationTeam_short')"
            display="chip"
            :style="{ 'max-width': '100px' }"
          />
        </template>
      </Column> -->

      <!-- <Column
        field="latestInstallationAppointment.calendarWeek"
        filterField="calendarWeek"
        :header="$t('calendar_week_short')"
        sortable
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="latestInstallationAppointment.installationAt"
        filterField="installationAt"
        :header="$t('installationAt_short')"
        sortable
        :headerStyle="{ 'max-width': '110px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span
            v-if="
              slotProps.data.latestInstallationAppointment &&
                slotProps.data.latestInstallationAppointment.installationAt
            "
          >
            <b-badge
              :variant="
                getYesNoStateColor(
                  slotProps.data.latestInstallationAppointment.appointmentApprovedState
                )
              "
            >
              {{ $datetime(slotProps.data.latestInstallationAppointment.installationAt) }}
            </b-badge>
          </span>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column> -->
      <!-- 
      <Column
        field="latestInstallationAppointment.installationStartTime"
        filterField="installationStartTime"
        :header="$t('installationStartAt_short')"
        :headerStyle="{ 'max-width': '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span
            v-if="
              slotProps.data.latestInstallationAppointment &&
                slotProps.data.latestInstallationAppointment.installationStartTime
            "
          >
            {{ $time(slotProps.data.latestInstallationAppointment.installationStartTime) }}
          </span>
        </template>
      </Column> -->
      <!-- 
      <Column
        field="latestInstallationAppointment.requiredInstallationTimeinHours"
        filterField="requiredInstallationTimeinHours"
        :header="$t('requiredInstallationTimeinHours_short')"
        sortable
        :headerStyle="{ 'max-width': '95px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="latestInstallationAppointment.installationType"
        :header="$t('installationType_short')"
        sortable
        :headerStyle="{ 'max-width': '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span
            v-if="
              slotProps.data.latestInstallationAppointment &&
                slotProps.data.latestInstallationAppointment.installationType
            "
          >
            {{ $t(slotProps.data.latestInstallationAppointment.installationType + '_short') }}
          </span>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.installationTypes"
            :options="installationTypes"
            @input="onInstallationTypeFilter"
            optionLabel="label"
            :placeholder="$t('installationType_short')"
            :style="{ 'max-width': '65px' }"
          />
        </template>
      </Column> -->

      <Column
        field="solarPlant.guaranteeCode"
        :header="$t('guaranteeCode')"
        filterField="guaranteeCode"
        sortable
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="solarPlant.solarPlantOverallPower"
        :header="$t('solarPlantOverallPower_short')"
        sortable
        filterField="solarPlantOverallPower"
        :showFilterMenu="false"
        :headerStyle="{ 'max-width': '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          {{ $n(slotProps.data.solarPlant.solarPlantOverallPower, 'decimal_triple') }}
        </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            placeholder=">="
          />
        </template>
      </Column>

      <!-- <Column
        field="latestInstallationAppointment.appointmentApprovedState"
        :header="$t('appointmentApprovedState_short')"
        sortable
        filterField="appointmentApprovedState"
        :headerStyle="{ 'max-width': '90px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <b-badge
            :variant="
              getYesNoStateColor(
                slotProps.data.latestInstallationAppointment.appointmentApprovedState
              )
            "
          >
            {{ $t(slotProps.data.latestInstallationAppointment.appointmentApprovedState) }}
          </b-badge>
        </template>

        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="yesNo()"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column> -->

      <Column
        field="installation.description"
        :header="$t('description')"
        filterField="installationDescription"
        sortable
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="employer.name"
        :header="$t('EMPLOYER_SHORT')"
        filterField="employerName"
        sortable
        :showFilterMenu="false"
      >
      </Column>

      <Column v-if="isAdmin" field="client.name" :header="$t('client')" sortable> </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import { getYesNoStateColor } from '@/helpers/colors';
import { yesNo } from '@/helpers/enums';
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
// import Calendar from 'primevue/calendar';

export default {
  components: {
    DataTable,
    Breadcrumbs,
    Column,
    InputText,
    MultiSelect,
    Dropdown,
    // Calendar,
  },
  data() {
    return {
      loading: false,
      filterName: 'route-schedulings-paginated-custom-table-filters',
      defaultSortField: 'number',
      defaultSortOrder: -1,
      defaultFilters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        clientProjectNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        customerLastname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerFirstname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerZip: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        customerStreet: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerCity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customerPhone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        customerMobile: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        employerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        installationAt: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        solarPlantOverallPower: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        itemPlanningFinishedState: { value: null, label: null, matchMode: FilterMatchMode.EQUALS },
        calendarWeek: { value: null, matchMode: FilterMatchMode.EQUALS },
        guaranteeCode: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        requiredInstallationTimeinHours: { value: null, matchMode: FilterMatchMode.EQUALS },
        appointmentApprovedState: { value: null, matchMode: FilterMatchMode.EQUALS },
        installationDescription: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      defaultCustomFilters: {
        employers: [],
        clients: [], // @deprecated
        client: null,
        installationTeams: [],
        installationTypes: [],
      },
      tableState: {
        pagination: {
          page: 0,
          rowsPerPage: 10,
        },
        sortField: this.defaultSortField,
        sortOrder: this.defaultSortOrder,
        filterName: 'route-schedulings-paginated-table-filters',
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'isLoading',
      'getProjects',
      'getProjectCount',
      'getEmployers',
      'getClients',
      'getEnumValues',
    ]),
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('route_scheduling_list') },
      ];
    },
    projects() {
      return this.getProjects;
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },
    employers() {
      if (!this.tableState.customFilters.client) return [];

      const employers = this.getEmployers
        .filter((employer) => employer.client.id === this.tableState.customFilters.client.value)
        .map((employer) => {
          return { value: employer.id.toString(), label: employer.name };
        });
      return employers;
    },
    clients() {
      const clients = this.getClients.map((client) => {
        return { value: client.id.toString(), label: client.name };
      });
      return clients;
    },

    installationTeams() {
      const installationTeams = this.getEnumValues('InstallationTeam').map((installationTeam) => {
        return { value: installationTeam, label: this.$t(installationTeam) };
      });
      return installationTeams;
    },
    installationTypes() {
      const installationTypes = this.getEnumValues('InstallationType').map((installationType) => {
        return { value: installationType, label: this.$t(installationType) };
      });
      return installationTypes;
    },
    optionsYesNo() {
      return yesNo().map((option) => {
        return { value: option, label: this.$t(option) };
      });
    },

    /**
     * Disable employer dropdown if no client is selected
     */
    employerDropdownDisabled() {
      return this.tableState.customFilters.client ? false : true;
    },
  },
  methods: {
    ...mapActions([
      'fetchProjectsPaginated',
      'fetchProjectsPaginatedByClient',
      'fetchEmployers',
      'fetchClients',
      'fetchEmployersByClient',
      'fetchEnumValues',
    ]),
    /**
     * Load remote table data
     */
    async loadTableData() {
      if (this.isClient) {
        await this.fetchProjectsPaginatedByClient({
          clientId: this.getCurrentUser.client.id,
          page: this.pageOffset,
          pageSize: this.tableState.pagination.rowsPerPage,
          sortField: this.tableState.sortField,
          sortOrder: this.tableState.sortOrder,
          filters: { ...this.tableState.filters, ...this.tableState.customFilters },
        });
      } else if (this.isAdmin) {
        await this.fetchProjectsPaginated({
          page: this.pageOffset,
          pageSize: this.tableState.pagination.rowsPerPage,
          sortField: this.tableState.sortField,
          sortOrder: this.tableState.sortOrder,
          filters: { ...this.tableState.filters, ...this.tableState.customFilters },
        });
      }
    },
    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination.page = event.page;
      this.tableState.pagination.rowsPerPage = event.rows;
      this.loadTableData();
    },
    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sortField = event.sortField;
      this.tableState.sortOrder = event.sortOrder;
      this.loadTableData();
    },
    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadTableData();
    },
    async onRemoveFilters(event) {
      this.tableState.sortOrder = this.defaultSortOrder;
      this.tableState.sortField = this.defaultSortField;
      this.tableState.filters = this.defaultFilters;
      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 20;
      this.tableState.customFilters = this.defaultCustomFilters;
      // this.tableState.customFilters.employers = [];
      localStorage.removeItem(this.filterName);
      await this.loadTableData();
    },
    async onStateRestore(event) {
      this.setupFilters();

      if (this.tableState) {
        this.tableState.sortField = event.sortField ? event.sortField : this.defaultSortField;
        this.tableState.sortOrder = event.sortOrder ? event.sortOrder : this.defaultSortOrder;
        this.tableState.pagination.page = 0; // event.first / event.rows;
        this.tableState.pagination.rowsPerPage = event.rows;
        this.tableState.pagination.rowsPerPage = event.rows;
      }
      const customFiltersFromStorage = JSON.parse(localStorage.getItem(this.filterName));
      this.tableState.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;
    },

    /**
     *
     */
    async onEmployerFilter(value) {
      this.tableState.customFilters.employers = this.employers.filter((employer) =>
        value.includes(employer)
      );
      localStorage.setItem(this.filterName, JSON.stringify(this.tableState.customFilters));
      await this.loadTableData();
    },

    /**
     *
     */
    async onClientFilter(value) {
      this.tableState.customFilters.employers = [];
      this.tableState.customFilters.client = value;
      localStorage.setItem(this.filterName, JSON.stringify(this.tableState.customFilters));

      await this.loadTableData();
    },

    /**
     *
     */
    async onInstallationTeamFilter(value) {
      this.tableState.customFilters.installationTeams = this.installationTeams.filter(
        (installationTeam) => value.includes(installationTeam)
      );
      localStorage.setItem(this.filterName, JSON.stringify(this.tableState.customFilters));
      await this.loadTableData();
    },

    /**
     *
     */
    async onInstallationTypeFilter(value) {
      this.tableState.customFilters.installationTypes = this.installationTypes.filter(
        (installationType) => value.includes(installationType)
      );
      localStorage.setItem(this.filterName, JSON.stringify(this.tableState.customFilters));
      await this.loadTableData();
    },

    setupFilters() {
      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 15;
      this.tableState.customFilters = this.tableState.customFilters
        ? this.tableState.customFilters
        : this.defaultCustomFilters;
      this.tableState.sortField = this.tableState.sortField
        ? this.tableState.sortField
        : this.defaultSortField;

      this.tableState.sortOrder = this.tableState.sortOrder
        ? this.tableState.sortOrder
        : this.defaultSortOrder;
    },

    getYesNoStateColor,
    yesNo,
  },
  async created() {
    this.setupFilters();
  },
  async mounted() {
    await this.fetchEnumValues('InstallationTeam');
    await this.fetchEnumValues('InstallationType');

    if (this.isClient) {
      await this.fetchEmployersByClient(this.getCurrentUser.client.id);
      this.tableState.customFilters.client = {
        value: this.getCurrentUser.client.id.toString(),
        label: this.getCurrentUser.client.name,
      };
    } else if (this.isAdmin) {
      await this.fetchEmployers();
      await this.fetchClients();
    }
    this.loading = false;
    await this.loadTableData();
  },
};
</script>

<style lang="scss">
.p-column-filter-menu-button {
  display: none;
}
.p-column-filter-clear-button {
  display: none;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}

.p-calendar .p-datepicker {
  min-width: 100%;
  width: 29em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}
</style>
